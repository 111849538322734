import React from "react";
import { Link } from "react-router-dom";
import FavouritesRecordings from "./favourites-recordings.component";
import FavouritesArticles from "./favourites-articles.component";
import "./favourites.styles.scss";
import NotFound from "../../../components/NotFound";

const FavouritesLandings = (props) => {
  let isActiveRecordings = true;
  let isActiveArticles = false;
  if (props.match.params.favouriteId) {
    isActiveRecordings = props.match.params.favouriteId.includes("recordings");
    isActiveArticles = props.match.params.favouriteId.includes("articles");
  }

  if (isActiveRecordings || isActiveArticles) {
    return (
      <div className="tsession-container corporate-container">
        <div className="favourites-container">
          <h1>Favourites</h1>
          <div className="favourites-tabs">
            <Link
              to="/user/favourites/recordings"
              className={isActiveRecordings ? "active-tab" : "normal-tab"}
            >
              Recordings
            </Link>
            <Link
              to="/user/favourites/articles"
              className={isActiveArticles ? "active-tab" : "normal-tab"}
            >
              Articles
            </Link>
          </div>
          {isActiveRecordings && <FavouritesRecordings />}
          {isActiveArticles && <FavouritesArticles />}
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
};

export default FavouritesLandings;
