import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Users from "../pages/users/index";
import Teachers from "../pages/teachers/index";
import Corporate from "../pages/corporate/index";
import SessionLink from "./sessionLink";
import Onboard from "./users/Onboard";
import GetStarted from "./common/GetStarted";
import Page from "./Page";

import constant from "../Constant";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const authed = localStorage.getItem("Authtoken");
  const isStudent = localStorage.getItem("WOEstudentUserId");
  const isTeacher = localStorage.getItem("teacherID");
  const shouldOnboard = localStorage.getItem("shouldOnboard");

  const handleRender = (props) => {
    let { pathname } = props.location;

    if (constant.IS_CORPORATE_APP && !authed && pathname === "/") {
      return <Redirect to={{ pathname: "/signin" }} />;
    } else if (!constant.IS_CORPORATE_APP && !authed && pathname === "/") {
      return <Redirect to={{ pathname: "/getstarted" }} />;
    } else if (!authed && pathname !== "/getstarted") {
      return (
        <Redirect
          to={{ pathname: "/signin", state: { from: props.location } }}
        />
      );
    } else if (constant.IS_CORPORATE_APP && authed && pathname === "/") {
      return <Redirect to="/corporate/dashboard" />;
    } else if (authed && shouldOnboard && !pathname.startsWith("/onboard")) {
      return (
        <Redirect
          to={{ pathname: "/onboard", state: { from: props.location } }}
        />
      );
    } else if (authed && pathname.startsWith("/user") && isTeacher) {
      return (
        <Redirect
          to={{
            pathname: "/teacher/schedule",
            state: { from: props.location },
          }}
        />
      );
    } else if (authed && pathname.startsWith("/teacher") && isStudent) {
      return (
        <Redirect
          to={{ pathname: "/user/sessions", state: { from: props.location } }}
        />
      );
    } else if (authed && isTeacher && pathname === "/") {
      return <Redirect to={{ pathname: "/teacher/schedule" }} />;
    } else if (authed && isStudent && pathname === "/") {
      return <Redirect to={{ pathname: "/user/sessions" }} />;
    } else if (
      authed &&
      (pathname === "/user/session" || pathname.startsWith("/user/session/"))
    ) {
      pathname = pathname.replace("/user/session", "/user/sessions");
      return <Redirect to={pathname} />;
    } else if (authed && pathname.startsWith("/user/sessions/community/")) {
      pathname = pathname.replace(
        "/user/sessions/community/",
        "/user/sessions/group/"
      );
      return <Redirect to={pathname} />;
    } else if (authed && pathname.startsWith("/user/sessions/company/")) {
      pathname = pathname.replace(
        "/user/sessions/company/",
        "/user/sessions/group/"
      );
      return <Redirect to={pathname} />;
    } else if (authed && pathname.startsWith("/user/schedule/community/")) {
      pathname = pathname.replace(
        "/user/schedule/community/",
        "/user/schedule/group/"
      );
      return <Redirect to={pathname} />;
    } else if (authed && pathname.startsWith("/user/schedule/company/")) {
      pathname = pathname.replace(
        "/user/schedule/company/",
        "/user/schedule/group/"
      );
      return <Redirect to={pathname} />;
    } else if (
      authed &&
      (pathname === "/teacher/session" ||
        pathname.startsWith("/teacher/session/"))
    ) {
      pathname = pathname.replace("/teacher/session", "/teacher/sessions");
      return <Redirect to={pathname} />;
    } else if (authed && pathname.startsWith("/teacher/sessions/community/")) {
      pathname = pathname.replace(
        "/teacher/sessions/community/",
        "/teacher/sessions/group/"
      );
      return <Redirect to={pathname} />;
    } else if (authed && pathname.startsWith("/teacher/sessions/company/")) {
      pathname = pathname.replace(
        "/teacher/sessions/company/",
        "/teacher/sessions/group/"
      );
      return <Redirect to={pathname} />;
    } else if (authed && pathname.startsWith("/teacher/schedule/community/")) {
      pathname = pathname.replace(
        "/teacher/schedule/community/",
        "/teacher/schedule/group/"
      );
      return <Redirect to={pathname} />;
    } else if (authed && pathname.startsWith("/teacher/schedule/company/")) {
      pathname = pathname.replace(
        "/teacher/schedule/company/",
        "/teacher/schedule/group/"
      );
      return <Redirect to={pathname} />;
    } else if (
      authed &&
      (pathname === "/user/favourite" ||
        pathname.startsWith("/user/favourite/"))
    ) {
      pathname = pathname.replace("/user/favourite", "/user/favourites");
      return <Redirect to={pathname} />;
    } else {
      return (
        <Switch>
          <Route path="/sessionLink" exact component={SessionLink} />
          <Route path="/onboard" component={Onboard} />
          <Route path="/getstarted">
            <Page title={"Get Started"}>
              <GetStarted />
            </Page>
          </Route>
          <Route path="/user" component={Users} />
          <Route path="/teacher" component={Teachers} />
          <Route path="/corporate" component={Corporate} />
          <Redirect to="/" />
        </Switch>
      );
    }
  };

  return <Route {...rest} render={handleRender} />;
};

export default RestrictedRoute;
