import React from "react";
import constant from "./Constant";
import "./MobileView.css";
import apple_app_store from "./Assets/apple_app_store.svg";
import google_play from "./Assets/google_play.svg";
import wone_logo_rec from "./Assets/wone_logo_rec.svg";

const MobileView = () => {
  const handleAppUrl = (e) => {
    let appLink;
    if (e.target.alt === "Apple App Store") {
      appLink = constant.APPLE_APP_STORE;
    } else {
      appLink = constant.GOOGLE_PLAY_STORE;
    }
    window.open(appLink, "_blank");
  };

  return (
    <div className="mobile-view-container">
      <div className="mobile-view-header">
        <h6>In order to access WONE you need to download our app.</h6>
      </div>
      <div className="mobile-view-body">
        <div className="mobile-view-logo-container">
          <img src={wone_logo_rec} alt="" className="mobile-view-logo-icons" />
        </div>
        <p>
          Not to worry it’s really easy just follow one of the links below to
          get started!
        </p>
      </div>
      <div className="mobile-view-footer">
        <img
          src={apple_app_store}
          alt="Apple App Store"
          className="app-store-icons"
          onClick={(e) => handleAppUrl(e)}
        />
        <img
          src={google_play}
          alt="Google Play Store"
          className="app-store-icons"
          onClick={(e) => handleAppUrl(e)}
        />
      </div>
    </div>
  );
};

export default MobileView;
