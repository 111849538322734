import { gql } from "@apollo/client";

// User detail fragments
export const USER_PARTS = gql`
  fragment UserParts on UserType {
    id
    firstName
    lastName
    username
    email
    isFirstSignIn
    biometrics {
      vitalUserId
      stepCount
      lastNightsSleep
      lastMeasuredHeartrate
    }
    userdetail {
      photo
      role {
        id
        name
      }
      studentdetail {
        id
        availableCredits
        isSubscribed
        subscriptionExipiryDate
        location {
          id
          name
        }
        company {
          id
          name
          paymentLink
        }
        plans {
          id
          name
          displayPhrase
        }
        pillar {
          name
          displayPhrase
        }
        onboarding {
          id
        }
      }
      teacherdetail {
        id
      }
    }
  }
`;

//QUERY_USER
export const QUERY_USER = gql`
  query User($email: String!) {
    user(email: $email) {
      ...UserParts
    }
  }
  ${USER_PARTS}
`;

//DELETE BIOMETRICS USER
export const VITAL_DELETE_USER = gql`
  mutation VitalDeleteUser {
    vitalDeleteUser {
      ok
      user {
        ...UserParts
      }
    }
  }
  ${USER_PARTS}
`;

//CREATE BIOMETRICS USER
export const VITAL_CREATE_USER = gql`
  mutation VitalCreateUser {
    vitalCreateUser {
      ok
      user {
        id
        email
        ...UserParts
      }
    }
  }
  ${USER_PARTS}
`;

//JOIN_OUR_COMMUNITY
export const UPCOMING_GROUP_SESSION_LIST = gql`
  query UpcomingGroupSessionList {
    upcomingGroupSessionList {
      upcomingGroupSessions {
        id
        isLeadingTheSession
        instanceDisplayEndDateTime
        joiningLink
        instanceStartDateTime
        instanceEndDateTime
        sessionEndDateTime
        groupsessionpeoplejoiningSet {
          user {
            id
          }
        }
        groupSession {
          id
          photo
          description
          groupSessionFor
          name
          day
          classType {
            name
          }
          classObj {
            description
          }
          preparationMaterial
          corporateCompany {
            name
          }
        }
        teacher {
          id
          landscapePhoto
          userdetailObj {
            userObj {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
//JOIN_OUR_COMMUNITY
export const JOIN_OUR_COMMUNITY = gql`
  query JoinOurCommunity($roleName: String!) {
    joinOurCommunity(roleName: $roleName) {
      companySessionToday {
        id
        instanceDisplayEndDateTime
        joiningLink
        instanceStartDateTime
        instanceEndDateTime
        sessionEndDateTime
        groupsessionpeoplejoiningSet {
          user {
            userdetail {
              photo
            }
            id
            firstName
            lastName
          }
        }
        groupSession {
          id
          photo
          description
          groupSessionFor
          name
          day
          classType {
            name
          }
          classObj {
            description
          }
          preparationMaterial
          corporateCompany {
            name
          }
        }
        teacher {
          id
          landscapePhoto
          userdetailObj {
            userObj {
              id
              firstName
              lastName
            }
          }
        }
      }
      companySessionThisWeek {
        id
        instanceDisplayEndDateTime
        joiningLink
        instanceStartDateTime
        instanceEndDateTime
        sessionEndDateTime
        groupsessionpeoplejoiningSet {
          user {
            userdetail {
              photo
            }
            id
            firstName
            lastName
          }
        }
        groupSession {
          id
          photo
          description
          groupSessionFor
          name
          day
          classType {
            name
          }
          classObj {
            description
          }
          preparationMaterial
          corporateCompany {
            name
          }
        }
        teacher {
          id
          landscapePhoto
          userdetailObj {
            userObj {
              id
              firstName
              lastName
            }
          }
        }
      }
      communitySessionToday {
        id
        instanceDisplayEndDateTime
        joiningLink
        instanceStartDateTime
        instanceEndDateTime
        sessionEndDateTime
        groupsessionpeoplejoiningSet {
          user {
            userdetail {
              photo
            }
            id
            firstName
            lastName
          }
        }
        groupSession {
          id
          photo
          description
          groupSessionFor
          name
          day
          classObj {
            description
          }
          corporateCompany {
            id
          }
          classType {
            name
          }
          preparationMaterial
        }
        teacher {
          id
          landscapePhoto
          userdetailObj {
            userObj {
              id
              firstName
              lastName
            }
          }
        }
      }
      communitySessionThisWeek {
        id
        instanceDisplayEndDateTime
        joiningLink
        instanceStartDateTime
        instanceEndDateTime
        sessionEndDateTime
        groupsessionpeoplejoiningSet {
          user {
            userdetail {
              photo
            }
            id
            firstName
            lastName
          }
        }
        groupSession {
          id
          photo
          description
          groupSessionFor
          name
          day
          classObj {
            description
          }
          corporateCompany {
            id
          }
          classType {
            name
          }
          preparationMaterial
        }
        teacher {
          id
          landscapePhoto
          userdetailObj {
            userObj {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
//RECORDINGS
export const RECORDINGS = gql`
  query RecordingsAndArticles {
    recordingsAndArticles {
      recordings {
        id
        recordingUrl
        recordingUrlThumbnail
        recordingDurationText
        teacher {
          id
          userdetailObj {
            userObj {
              firstName
              lastName
            }
          }
        }
        groupSession {
          name
          description
          preparationMaterial
        }
      }
    }
  }
`;
//ARTICLES
export const ARTICLES = gql`
  query RecordingsAndArticles {
    recordingsAndArticles {
      articles {
        id
        photo
        title
        author
      }
    }
  }
`;

//RECORDINGS DETAILS
export const RECORDINGS_DETAILS = gql`
  query GroupSessionInstance($id: ID!) {
    groupSessionInstance(id: $id) {
      id
      recordingUrl
      recordingUrlThumbnail
      recordingDurationText
      teacher {
        userdetailObj {
          userObj {
            firstName
            lastName
          }
        }
      }
      groupSession {
        id
        name
        description
        preparationMaterial
        groupSessionFor
      }
    }
  }
`;
//ARTICLE DETAILS
export const ARTICLE_DETAILS = gql`
  query Article($id: ID!) {
    article(id: $id) {
      id
      title
      author
      photo
      content
    }
  }
`;

//USER FAVORITES ARTICLE
export const USER_FAVORITES_ARTICLE = gql`
  query UserFavorites {
    userFavourites {
      id
      totalArticles
      articles {
        id
        photo
        title
        author
      }
    }
  }
`;

//USER FAVORITES ARTICLES
export const USER_FAVORITES_ARTICLES = gql`
  query UserFavorites {
    userFavourites {
      id
      totalArticles
      articles {
        id
        photo
        title
        author
      }
    }
  }
`;

// USER FAVORITES RECORDINGS

export const USER_FAVORITES_RECORDINGS = gql`
  query UserFavorites {
    userFavourites {
      id
      totalRecordings
      recordings {
        id
        recordingUrl
        recordingUrlThumbnail
        recordingDurationText
        teacher {
          id
          userdetailObj {
            userObj {
              firstName
              lastName
            }
          }
        }
        groupSession {
          name
          description
          preparationMaterial
        }
      }
    }
  }
`;
// STUDENT PROFILE PAGE

export const STUDENT_PROFILE_PAGE = gql`
  query StudentProfilePage {
    studentProfilePage {
      studentData {
        assessmentTaken
        userdetailObj {
          photo
        }
      }
      historyTotalCount
      externalAssessmentUrl
      achievements {
        totalWellbeingHours
      }
      externalAssessmentWebAppDisplayText
      externalAssessmentDisplayButtonText
      externalAssessmentWaitingVideoTitle
      externalAssessmentWaitingVideoClip
      retakeAssessmentDisplayButtonText
      retakeAssessmentReminderDisplayButtonText
      retakeAssessmentReminder
      isWaitingForAssessmentResults
      externalAssessment {
        assessmentPercentage
        id
        nutritionScorePercentage
        nutritionDescription
        energyScorePercentage
        energyDescription
        resilienceScorePercentage
        resilienceDescription
        createdAt
      }
      pillarRecommendations {
        energy {
          articles(limit: 2, order: "random") {
            id
            title
            author
            photo
          }
          sessions {
            id
            instanceStartDateTime
            instanceDisplayEndDateTime
            instanceEndDateTime
            sessionEndDateTime
            joiningLink
            groupsessionpeoplejoiningSet {
              user {
                userdetail {
                  photo
                }
                id
                firstName
                lastName
              }
            }
            groupSession {
              name
              photo
              id
              description
              preparationMaterial
              classObj {
                name
                description
              }
              classType {
                name
              }
              day
              groupSessionFor
              corporateCompany {
                name
              }
            }
            teacher {
              id
              landscapePhoto
              userdetailObj {
                userObj {
                  firstName
                  lastName
                  id
                }
              }
            }
          }
          recordings(limit: 2, order: "random") {
            id
            recordingUrl
            recordingUrlThumbnail
            recordingDurationText
            groupSession {
              name
            }
          }
        }
        resilience {
          articles(limit: 2, order: "random") {
            id
            title
            author
            photo
          }
          sessions {
            id
            instanceStartDateTime
            instanceDisplayEndDateTime
            instanceEndDateTime
            sessionEndDateTime
            joiningLink
            groupsessionpeoplejoiningSet {
              user {
                userdetail {
                  photo
                }
                id
                firstName
                lastName
              }
            }
            groupSession {
              name
              photo
              id
              description
              preparationMaterial
              classObj {
                name
                description
              }
              classType {
                name
              }
              day
              groupSessionFor
              corporateCompany {
                name
              }
            }
            teacher {
              id
              landscapePhoto
              userdetailObj {
                userObj {
                  firstName
                  lastName
                  id
                }
              }
            }
          }
          recordings(limit: 2, order: "random") {
            id
            recordingUrl
            recordingUrlThumbnail
            recordingDurationText
            groupSession {
              name
            }
          }
        }
        activity {
          articles(limit: 2, order: "random") {
            id
            title
            author
            photo
          }
          sessions {
            id
            instanceStartDateTime
            instanceDisplayEndDateTime
            instanceEndDateTime
            sessionEndDateTime
            joiningLink
            groupsessionpeoplejoiningSet {
              user {
                userdetail {
                  photo
                }
                id
                firstName
                lastName
              }
            }
            groupSession {
              name
              photo
              id
              description
              preparationMaterial
              classObj {
                name
                description
              }
              classType {
                name
              }
              day
              groupSessionFor
              corporateCompany {
                name
              }
            }
            teacher {
              id
              landscapePhoto
              userdetailObj {
                userObj {
                  firstName
                  lastName
                  id
                }
              }
            }
          }
          recordings(limit: 2, order: "random") {
            id
            recordingUrl
            recordingUrlThumbnail
            recordingDurationText
            groupSession {
              name
            }
          }
        }
      }
    }
  }
`;

export const PILLAR_LIST = gql`
  query PillarList {
    pillarList {
      ordering
      name
      description
    }
  }
`;
