import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { logAnalyticsEvent } from "../../utility/FirebaseAnalytics";
import { useAuth } from "../../context/auth";
import useQueryParams from "../../components/commons/hooks/useQueryParams";

import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Corporate from "./index";

import "../common/styles.scss";
import Page from "../Page";

const LoadingPage = ({ children }) => {
  return (
    <div className="Background">
      <div className="Page">
        <div className="Error">
          <h1>{children}</h1>
        </div>
      </div>
    </div>
  );
};

const trackUserPaymentSuccess = (user) => {
  const userId = user.id;
  const companyId = user?.userdetail?.studentdetail?.company?.id;
  const companyName = user?.userdetail?.studentdetail?.company?.name || "NA";
  const trackPaymentEventsSent = localStorage.getItem("trackPaymentEventsSent");

  if (!trackPaymentEventsSent) {
    if (process.env.REACT_APP_ENV === "prod" && window.lintrk != null) {
      window.lintrk("track", { conversion_id: 10357266 });
    }

    logAnalyticsEvent("starter_payment_confirmed", {
      user_id: userId,
      user_id_wone: userId,
      company_id: companyId,
      company_name: companyName,
    });

    localStorage.setItem("trackPaymentEventsSent", "true");
  }
};

const Routes = ({ component: Component, ...rest }) => {
  const { user } = useAuth();
  const params = useQueryParams();

  const handleRender = (props) => {
    const { pathname } = props.location;
    const authToken = localStorage.getItem("Authtoken");
    const paymentLink = localStorage.getItem("paymentLink");
    const paymentStatus = params.get("payment_status");

    if (
      user &&
      paymentStatus === "success" &&
      document.referrer.includes("checkout.stripe.com")
    ) {
      localStorage.setItem("shouldShowWelcome", "true");
      trackUserPaymentSuccess(user);
    }

    if (authToken && !user) {
      return <LoadingPage>Loading...</LoadingPage>;
    } else if (user && pathname === "/login") {
      return <Redirect to="/" />;
    } else if (user && pathname === "/signin") {
      return <Redirect to="/" />;
    } else if (user && pathname === "/signup") {
      return <Redirect to="/" />;
    } else if (user && paymentLink && paymentStatus !== "success") {
      window.location.href = paymentLink;
      return <LoadingPage>Loading...</LoadingPage>;
    } else if (user && pathname === "/") {
      return <Redirect to="/dashboard" />;
    }

    return (
      <Switch>
        <Route
          path="/login"
          render={(props) => (
            <Page title="Sign In">
              <SignIn {...props} />
            </Page>
          )}
        />
        <Route
          path="/signin"
          render={(props) => (
            <Page title="Sign In">
              <SignIn {...props} />
            </Page>
          )}
        />
        <Route
          path="/signup"
          render={(props) => (
            <Page title="Sign Up">
              <SignUp {...props} />
            </Page>
          )}
        />
        {!authToken ? (
          <Redirect
            to={{ pathname: "/signin", state: { from: props.location } }}
          />
        ) : (
          <Corporate />
        )}
      </Switch>
    );
  };

  return <Route {...rest} render={handleRender} />;
};

export default Routes;
