import React from "react";
import { Route, Switch, Redirect, Link, useLocation } from "react-router-dom";
import SNavbar from "../../components/commons/Dashboard/SNavbar";
import Page from "../Page";
import Schedule from "./Schedule/Schedule";
import Session from "./Session/Session";
import WoneIndex from "./woneIndex/wone-index";
import WonderIndexDetails from "./woneIndex/wonderIndexDetails/wonderIndexDetails.component";
import Recordings from "./Recordings/recordings.component";
import RecordingsDetail from "./Recordings/recording-detail.component";
import Articles from "./Articles/articles.component";
import ArticleDetail from "./Articles/article-detail.component";
import FavouritesLandings from "./Favourites/favourites-landings.component";
import JoinSession from "./JoinSession/join-session-component";
import FeedbackByStudentAfterJoinSession from "./FeedBack/FeedbackByStudentAfterJoinSession";
import NotFound from "../../components/NotFound";
import AUPolicy from "./terms/aupolicy";
import Biometrics from "./Biometrics/biometrics.component";

const UserRoutes = [
  {
    Title: "Sessions",
    Exact: true,
    Path: "/user/sessions",
    Component: Session,
  },
  {
    Title: "Schedule",
    Exact: true,
    Path: "/user/schedule",
    Component: Schedule,
  },
  {
    Title: "Sessions",
    Exact: true,
    Path: "/user/sessions/:sessionType/:sessionId",
    Component: Session,
  },
  {
    Title: "Schedule",
    Exact: true,
    Path: "/user/schedule/:sessionType/:sessionId",
    Component: Schedule,
  },
  {
    Title: "WONE Index",
    Exact: true,
    Path: "/user/woneindex",
    Component: WoneIndex,
  },
  {
    Title: "WONE Index",
    Exact: true,
    Path: "/user/woneindex/external-assessment/complete",
    Component: WoneIndex,
  },
  {
    Title: "WONE Index",
    Exact: true,
    Path: "/user/woneindex/:woneindexType",
    Component: WonderIndexDetails,
    skipTitle: true,
  },
  {
    Title: "Recordings",
    Exact: true,
    Path: "/user/recordings",
    Component: Recordings,
  },
  {
    Title: "Recordings",
    Exact: true,
    Path: "/user/recordings/:recordingId",
    Component: RecordingsDetail,
    skipTitle: true,
  },
  {
    Title: "Articles",
    Exact: true,
    Path: "/user/articles",
    Component: Articles,
  },
  {
    Title: "Articles",
    Exact: true,
    Path: "/user/articles/:articleId",
    Component: ArticleDetail,
    skipTitle: true,
  },
  {
    Title: "Biometrics",
    Exact: true,
    Path: "/user/biometrics",
    Component: Biometrics,
  },
  {
    Title: "Favourites",
    Exact: true,
    Path: "/user/favourites",
    Component: FavouritesLandings,
  },
  {
    Title: "Favourites",
    Exact: true,
    Path: "/user/favourites/:favouriteId",
    Component: FavouritesLandings,
  },
  {
    Title: "Feedback",
    Exact: true,
    Path: "/user/sessions/:sessionType/:sessionId/meeting/feedback",
    Component: FeedbackByStudentAfterJoinSession,
  },
  {
    Title: "Meeting",
    Exact: true,
    Path: "/user/sessions/:sessionType/:sessionId/meeting",
    Component: JoinSession,
  },
  {
    Title: "Acceptable Use Policy",
    Exact: true,
    Path: "/user/terms/acceptableusepolicy",
    Component: AUPolicy,
  },
  {
    Title: "Page Not Found",
    Component: NotFound,
  },
];

const Users = (props) => {
  const { pathname } = useLocation();
  return (
    <>
      {!props.location?.state?.zoomMtgNumber &&
        !props.location?.pathname.includes("meeting/feedback") && <SNavbar />}
      <Switch>
        {UserRoutes.map((Routes, index) => {
          if (
            Routes?.Path === "/user/sessions/:sessionType/:sessionId/meeting" &&
            !props.location.state
          ) {
            return (
              <Redirect
                to={{
                  pathname: props.location.pathname.replace("/meeting", ""),
                  state: { from: props.location },
                }}
              />
            );
          }
          return (
            <Route
              key={index}
              path={Routes.Path}
              exact={Routes?.Exact}
              render={(props) =>
                Routes?.skipTitle ? (
                  <Routes.Component {...props} />
                ) : (
                  <Page title={Routes.Title}>
                    <Routes.Component {...props} />
                  </Page>
                )
              }
            />
          );
        })}
      </Switch>
      {pathname !== "/user/terms/acceptableusepolicy" && (
        <div className="aupolicy-link">
          <Link to="/user/terms/acceptableusepolicy">
            Acceptable Use Policy
          </Link>
        </div>
      )}
    </>
  );
};

export default Users;
