import sessionIcon from "../../../Assets/sessionIcon.svg";
import scheduleIcon from "../../../Assets/calendarClock.svg";
import recordingsIcon from "../../../Assets/recordingsIcon.svg";
import articlesIcon from "../../../Assets/articlesIcon.svg";
import favouriteIcon from "../../../Assets/favourite_icon.svg";
import woeIndexIcon from "../../../Assets/woe_index.svg";
const SidebarData = [
  {
    title: "Sessions",
    path: "/user/sessions",
    imgName: sessionIcon,
    cName: "nav-text",
  },
  {
    title: "My Schedule",
    path: "/user/schedule",
    imgName: scheduleIcon,
    cName: "nav-text",
  },
  {
    title: "WONE Index",
    path: "/user/woneindex",
    imgName: woeIndexIcon,
    cName: "nav-text",
  },
  {
    title: "Recordings",
    path: "/user/recordings",
    imgName: recordingsIcon,
    cName: "nav-text",
  },
  {
    title: "Articles",
    path: "/user/articles",
    imgName: articlesIcon,
    cName: "nav-text",
  },
  {
    title: "Favourites",
    path: "/user/favourites",
    imgName: favouriteIcon,
    cName: "nav-text",
  },
  {
    title: "Biometrics",
    path: "/user/biometrics",
    imgName: woeIndexIcon,
    cName: "nav-text",
  },
];

export default SidebarData;
