import firebase from "firebase/app";
import "firebase/analytics";

import constants from "../Constant";

const userId = localStorage.getItem("userID");

let firebaseAnalytics = null;
let firebaseConfig = {
  apiKey: constants.FIREBASE_API_KEY,
  authDomain: constants.FIREBASE_APP_DOMAIN,
  databaseURL: constants.FIREBASE_DATABASE_URL,
  projectId: constants.FIREBASE_PROJECT_ID,
  storageBucket: constants.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: constants.FIREBASE_MESSAGING_SENDER_ID,
  appId: constants.FIREBASE_APP_ID,
  measurementId: constants.FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

// ref: https://stackoverflow.com/questions/64155540
window.dataLayer = window.dataLayer || [];

function gtag() {
  window.dataLayer.push(arguments);
}

gtag("set", { send_page_view: false });

firebaseAnalytics = firebase.analytics();
firebaseAnalytics.setUserId(userId);

const setAnalyticsUserId = firebaseAnalytics.setUserId;
const logAnalyticsEvent = (eventName, eventParams, options) => {
  if (eventParams !== undefined) {
    eventParams = {
      app_name: constants.APP_NAME,
      app_version: constants.APP_VERSION,
      ...eventParams,
    };
  }

  firebaseAnalytics.logEvent(eventName, eventParams, options);
};

export default firebaseAnalytics;
export { logAnalyticsEvent, setAnalyticsUserId };
