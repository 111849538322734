import React from "react";
import { Jumbotron } from "reactstrap";
import "./card.css";
const Card = (props) => {
  return (
    <Jumbotron className="p-3 mb-0 rounded-0 card bg-white border-0">
      {props.children}
    </Jumbotron>
  );
};
export default Card;
