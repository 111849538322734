import React, { useState, useRef, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import FeedbackByStudentAfterVideo from "./FeedbackByStudentAfterVideo";
import SecondMessageWindow from "./SecondMessageWindow";
import LastMessageWindow from "./LastMessageWindow";
import useQueryParams from "../../../components/commons/hooks/useQueryParams";
import ProgressLinearBar from "../../../components/commons/ProgressLinearBar";
import "./feedback.styles.scss";

const FEEDBACK_CHARACTERISTIC_LIST = gql`
  query FeedbackCharacteristicList {
    feedbackCharacteristicList {
      id
      name
    }
  }
`;

const CLASS_RATING_LIST = gql`
  query ClassRatingList {
    classRatingList {
      id
      name
    }
  }
`;

const FeedbackByStudentAfterJoinSession = () => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const query = useQueryParams();
  const sessionId = query.get("sessionId");
  const teacherId = query.get("teacherId");
  const moodBefore = query.get("moodBefore");
  const sessionType = query.get("sessionType");
  const sessionTitle = query.get("sessionTitle");
  document.title = !!sessionTitle
    ? `Feedback - ${sessionTitle} - Sessions`
    : `Feedback`;

  const [newaboutTeacher, setnewaboutTeacher] = useState([]);
  const [Note, setNote] = useState("");
  const [expectationItem, setexpectationItem] = useState("");
  const [expectationItemName, setexpectationItemName] = useState("");
  const [isFeedbackByStudentAfterVideo, setisFeedbackByStudentAfterVideo] =
    useState(true);
  const [isSecondMessageWindow, setisSecondMessageWindow] = useState(false);
  const [isLastMessageWindow, setisLastMessageWindow] = useState(false);
  const [moodeAfterFeedBack, setMoodAfterFeedback] = useState(0);
  const [aboutTeacherFeedbackText, setAboutTeacherFeedbackText] = useState("");
  const [isBtnDisabled, setButtonDisabled] = useState(false);

  const { data: dataFCL } = useQuery(FEEDBACK_CHARACTERISTIC_LIST, {
    fetchPolicy: "no-cache",
  });
  const { data: dataCRL } = useQuery(CLASS_RATING_LIST, {
    fetchPolicy: "no-cache",
  });

  const handleVideoCallThird = (item, name) => {
    setisSecondMessageWindow(true);
    setisFeedbackByStudentAfterVideo(false);
    setexpectationItem(item);
    setexpectationItemName(name);
  };

  const handleVideoCallFourth = (
    newaboutTeacher,
    Note,
    aboutTextFeedbackText
  ) => {
    setisLastMessageWindow(true);
    setisSecondMessageWindow(false);
    setnewaboutTeacher(newaboutTeacher);
    setNote(Note);
    setAboutTeacherFeedbackText(aboutTextFeedbackText.join(", "));
  };

  const getProgressBarPercentage = () => {
    let percent = "33";
    if (isLastMessageWindow) {
      percent = "100";
    } else if (isSecondMessageWindow && !isLastMessageWindow) {
      percent = "66";
    }
    return percent;
  };

  useEffect(() => {
    scrollToBottom();
  }, [isSecondMessageWindow, isLastMessageWindow]);

  return (
    <div className="FeedbackByStudentAfterVideo-container">
      <div className="FeedbackByStudentAfterVideo-message-window">
        <div className="FeedbackByStudentAfterVideo-progressor">
          <p>Session completed!</p>
          <ProgressLinearBar done={getProgressBarPercentage()} />
        </div>

        <div className="FeedbackByStudentAfterVideo-chat">
          {
            <FeedbackByStudentAfterVideo
              isFeedbackByStudentAfterVideo={isFeedbackByStudentAfterVideo}
              setMoodAfterFeedback={setMoodAfterFeedback}
              handleVideoCallThird={handleVideoCallThird}
              dataCRL={dataCRL}
              setButtonDisabled={setButtonDisabled}
            />
          }
          {(isSecondMessageWindow || isLastMessageWindow) && (
            <SecondMessageWindow
              isSecondMessageWindow={isSecondMessageWindow}
              expectationItemName={expectationItemName}
              expectationItem={expectationItem}
              handleVideoCallFourth={handleVideoCallFourth}
              dataFCL={dataFCL}
            />
          )}
          {isLastMessageWindow && (
            <LastMessageWindow
              aboutTeacherFeedbackText={aboutTeacherFeedbackText}
              moodeAfterFeedBack={moodeAfterFeedBack}
              sessionType={sessionType}
              moodBefore={moodBefore}
              newaboutTeacher={newaboutTeacher}
              Note={Note}
              sessionId={sessionId}
              teacherId={teacherId}
              expectationItem={expectationItem}
              isBtnDisabled={isBtnDisabled}
            />
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
  );
};

export default FeedbackByStudentAfterJoinSession;
