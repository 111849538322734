import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import employeeOptions from "../../../Assets/employeeOptions.svg";
import AddLocation from "./AddLocation";
import AddAdmin from "./AddAdmin";
import RemoveAdmin from "./RemoveAdmin";
import functions from "../../../functions";
//import { useAuth } from "../../../context/auth";

import { toast } from "react-toastify";
import defaultImage from "../../../Assets/upload_photo.svg";
import profileFallback from "../../../Assets/profile_fallback.png";

import "./CompanyDetails.css";
import {
  GET_CORPORATE_COMPANY_BY_ID,
  QUERY_UPDATECORPORATECOMPANY,
} from "../GraphQl";
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons";

const CompanyDetails = () => {
  const [companyName, setCompanyName] = useState("");

  const [hasCompanyDetailsChanged, setHasCompanyDetailsChanged] =
    useState(false);

  //const [billingEmail, setBillingEmail] = useState();
  const [addLocation, setAddLocation] = useState(false);
  const [addAdmin, setAddAdmin] = useState(false);

  let [companyDetails, setCompanyDetails] = useState(null);
  //const [imageObj, setImageObj] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [indexDeleteAdmin, setIndexDeleteAdmin] = useState("");
  const [removeButtonVisible, setRemoveButtonVisible] = useState(false);
  const loggedInUserEmailId = localStorage.getItem("userEmailId");
  //const { user } = useAuth();
  const [loadCompanyDetails, { loading, error }] = useLazyQuery(
    GET_CORPORATE_COMPANY_BY_ID,
    {
      fetchPolicy: "no-cache",
      onCompleted(response) {
        setCompanyDetails(response?.corporateCompany);
        setProfileImage(response?.corporateCompany?.photo);
        setCompanyName(response?.corporateCompany.name);
      },
      onError(error) {
        console.log("loadCompanyDetails error", error);
      },
    }
  );

  const [updateCorporateCompany] = useMutation(QUERY_UPDATECORPORATECOMPANY);

  const handleCompanyNameChange = (e) => {
    if (companyName.length === 0) {
      setHasCompanyDetailsChanged(false);
    } else if (e.target.value === "") {
      setHasCompanyDetailsChanged(false);
    } else if (companyDetails.name === e.target.value) {
      setHasCompanyDetailsChanged(false);
    } else {
      setHasCompanyDetailsChanged(true);
    }
    setCompanyName(e.target.value);
  };

  /* const handleBillingEmailChange = (e) => {
    setBillingEmail(e.target.value);
  }; */

  const handleAddAdmin = () => {
    setAddAdmin(true);
  };

  const closeForm = (reload) => {
    setAddLocation(false);
  };

  const CloseAdminForm = (reload) => {
    setAddAdmin(false);
  };

  const handleCompanyDetails = () => {
    let postData = {
      id: localStorage.getItem("companyID"),
      input: {},
    };
    //approach 1 feature start here
    /* if (imageObj) {
      functions.uploadFile(imageObj, (response) => {
        postData.input["photo"] = response.Location;
        updateCompanyDetails(postData);
      });
    } */
    // approach 1 feature end  here

    if (companyName !== companyDetails.name) {
      postData.input["name"] = companyName;
      updateCompanyDetails(postData);
    }
  };

  const updateCompanyDetails = (postData) => {
    updateCorporateCompany({
      variables: postData,
    })
      .then((response) => {
        //setImageObj(null);
        setHasCompanyDetailsChanged(false);
        setProfileImage(
          response?.data.updateCorporateCompany?.corporateCompany?.photo
        );
        toast.success(`Company name successfully updated`);
      })
      .catch((err) => {
        toast.error(`Error while updating company details ${err}`);
      });
  };

  const updateCompanyDetailsOnDOM = () => {
    loadCompanyDetails({
      variables: { id: localStorage.getItem("companyID") },
    });
  };

  const handleChangeImage = (event) => {
    let imageObj = event.target.files[0];

    if (imageObj) {
      //setImageObj(imageObj);
      getBase64(imageObj, (base64Image) => {
        setProfileImage(base64Image);
      });

      if (imageObj.size > 2001094) {
        toast.error("Maximum 2 MB image size is allowed");
        return false;
      } else {
        //setHasCompanyDetailsChanged(true); // for approach 1 feature start here
        // approach 2 feature start here
        let postData = {
          id: localStorage.getItem("companyID"),
          input: {},
        };

        functions.uploadFile(imageObj, (response) => {
          postData.input["photo"] = response.Location;
          updateCorporateCompany({
            variables: postData,
          })
            .then((response) => {
              //setImageObj(null);
              setHasCompanyDetailsChanged(false);
              setProfileImage(
                response?.data?.updateCorporateCompany?.corporateCompany?.photo
              );
              toast.success(`Company logo successfully uploaded`);
            })
            .catch((err) => {
              toast.error(`Error while updating the Company logo ${err}`);
            });
        });
        // approach 2 feature end here
      }
    }
  };
  const getBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleRemoveAdmin = (index) => {
    setIndexDeleteAdmin(index);
    setRemoveButtonVisible(true);
  };
  const closeModel = (result) => {
    setIndexDeleteAdmin("");
    setRemoveButtonVisible(result);
  };
  useEffect(() => {
    loadCompanyDetails({
      variables: { id: localStorage.getItem("companyID") },
    });
  }, [loadCompanyDetails]);

  if (loading)
    return (
      <div className="companyDetails-container corporate-container">
        <h1>Loading..</h1>
      </div>
    );

  if (error)
    return (
      <div className="companyDetails-container corporate-container">
        <h1>Something went wrong! Please try again.</h1>
      </div>
    );

  return (
    companyDetails && (
      <div className="companyDetails-container corporate-container">
        <>
          <div className="companyDetails-wrapper">
            <p className="companyDetails-container-p">Company details</p>
            <div className="companyDetails">
              <div className="d-flex justify-content-between flex-lg-row flex-column-reverse ">
                <div className="companyDetails-left mb-2 mr-md-2">
                  <div className="companyName">
                    <label>Company name</label>
                    <input
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={handleCompanyNameChange}
                    />
                  </div>
                  <div className="billingEmail">
                    <label>Billing email</label>
                    <input
                      type="text"
                      name="billingEmail"
                      value={companyDetails.billingEmail}
                      //onChange={handleBillingEmailChange}
                      disabled
                    />
                  </div>
                </div>
                <div className="companyDetails-right mb-2">
                  <label>Logo</label>
                  <div className="logo-changeLogo">
                    <img
                      className="Companylogo"
                      src={
                        profileImage === null
                          ? `${defaultImage}`
                          : `${profileImage}`
                      }
                      alt="Profile Pic"
                    />

                    <div className="ChangeLogo">
                      <div className="fileinputs">
                        <input
                          type="file"
                          value=""
                          accept="image/*"
                          title="Click to upload a profile image"
                          onChange={(e) => handleChangeImage(e)}
                        />
                      </div>
                      <h5>JPG or PNG, at least 256px</h5>
                    </div>
                  </div>
                </div>
              </div>
              <PrimaryButton
                type="button"
                onClick={handleCompanyDetails}
                className="hand-cursor companyDetails-left-button"
                disabled={!hasCompanyDetailsChanged}
              >
                Save changes
              </PrimaryButton>
            </div>
          </div>

          <div className="admins-wrapper">
            <p className="companyDetails-container-p">Admins</p>
            <div className="admins">
              <div className="admins-content-wrapper">
                <div className="admins-heading">
                  <p className="admins-heading-first">Admin</p>
                  {/* <p className="admins-heading-second">Main Location</p> */}
                </div>
                <hr className="locations-solid"></hr>
                {companyDetails.corporatecompanyadminSet.map((res, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="admins-data">
                        <div className="employee-profile">
                          <img
                            className="temp-image"
                            src={
                              res?.admin?.userdetailObj?.photo === null
                                ? profileFallback
                                : res?.admin?.userdetailObj?.photo
                            }
                            alt=""
                          />
                          <div className="employee-name">
                            <p className="company-admin-name">
                              {res.admin.userdetailObj.userObj.firstName}{" "}
                              {res.admin.userdetailObj.userObj.lastName}
                            </p>
                            <p className="company-admin-email">
                              {res.admin.userdetailObj.userObj.email}
                            </p>
                          </div>
                        </div>
                        <div className="admins-data-member">
                          {/* <p>
                            {res.admin.userdetailObj.userObj.email ===
                            loggedInUserEmailId
                              ? user?.userdetail?.studentdetail?.location?.name
                              : res?.location?.name}
                          </p> */}
                          <p></p>
                          {res.admin.userdetailObj.userObj.email !==
                          loggedInUserEmailId ? (
                            <div className="remove-admin-button-container">
                              <img
                                src={employeeOptions}
                                alt=""
                                onClick={() => handleRemoveAdmin(index)}
                              />
                              {removeButtonVisible &&
                                indexDeleteAdmin === index && (
                                  <RemoveAdmin
                                    adminId={res.id}
                                    closeModel={closeModel}
                                    updateCompanyDetailsOnDOM={
                                      updateCompanyDetailsOnDOM
                                    }
                                  />
                                )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <hr className="locations-solid"></hr>
                    </React.Fragment>
                  );
                })}
                <SecondaryButton onClick={handleAddAdmin}>
                  Add admin
                </SecondaryButton>
              </div>
            </div>
          </div>
        </>

        {addLocation && (
          <AddLocation
            closeForm={closeForm}
            updateCompanyDetailsOnDOM={updateCompanyDetailsOnDOM}
          />
        )}
        {addAdmin && (
          <AddAdmin
            CloseAdminForm={CloseAdminForm}
            updateCompanyDetailsOnDOM={updateCompanyDetailsOnDOM}
          />
        )}
      </div>
    )
  );
};
export default CompanyDetails;
