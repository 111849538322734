import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Router } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { onError } from "apollo-link-error";
import { setContext } from "@apollo/client/link/context";
import { createBrowserHistory } from "history";
import LinkedInTag from "react-linkedin-insight";

import constant from "../src/Constant";
import Home from "./pages";
import { useMobileView } from "./utility/useMobileView";
import { AuthProvider } from "./context/auth";
import ErrorBoundary from "./pages/common/ErrorBoundary";
import { clearStorage } from "./utility/Function";
import { setAnalyticsUserId } from "./utility/FirebaseAnalytics";
import { RetryLink } from "@apollo/client/link/retry";

if (process.env.REACT_APP_ENV === "prod" && constant.IS_CORPORATE_APP) {
  LinkedInTag.init(constant.LINKEDIN_PARTNER_ID);
}

function App() {
  const history = createBrowserHistory();
  const httpLink = createHttpLink({
    uri: constant.API_URL,
  });
  const { isMobileBrowser, MobileView } = useMobileView();
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error,
    },
  });

  const authenticationFailed = () => {
    alert("Your token has expired. Please log in again.");
    history.go(0);
    clearStorage();
    setAnalyticsUserId(null);
  };

  const errorControl = onError(({ networkError, graphQLErrors, operation }) => {
    const { response } = operation.getContext();
    if (response?.status === 401) {
      authenticationFailed();
    } else if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (
          message === "Error decoding signature" ||
          message === "User is disabled"
        ) {
          authenticationFailed();
        }
      });
    }
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("Authtoken");

    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: errorControl.concat(retryLink.concat(authLink.concat(httpLink))),
    cache: new InMemoryCache(),
  });

  return (
    <ErrorBoundary>
      <Router history={history}>
        <ApolloProvider client={client}>
          <AuthProvider>
            {isMobileBrowser ? <MobileView /> : <Home />}
          </AuthProvider>
        </ApolloProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
