import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import PrivateRoute from "./PrivateRoute";
import CRoutes from "./corporate/Routes";
import constants from "../Constant";
import Page from "./Page";
//import SignUp from "./common/SignUp";
import SignIn from "./common/SignIn";
import ChangePassword from "./common/ChangePassword";
import ResetPassword from "./common/ResetPassword";
import CSignUp from "./corporate/SignUp";
import CSignIn from "./corporate/SignIn";
import { ToastContainer } from "react-toastify";

const UserRoutesArr = [
  {
    Title: "Sign In",
    Exact: true,
    Path: "/login",
    Component: SignIn,
  },
  /* {
    Title: "Sign Up",
    Path: "/signup",
    Component: SignUp,
  }, */
  {
    Title: "Change Password",
    Path: "/signin/create",
    Component: ChangePassword,
  },
  {
    Title: "Reset Password",
    Path: "/signin/reset",
    Component: ResetPassword,
  },
  {
    Title: "Sign In",
    Path: "/signin",
    Component: SignIn,
  },
];

class Home extends Component {
  render() {
    const history = createBrowserHistory();
    return (
      <>
        <ToastContainer />
        <Router history={history}>
          <Switch>
            {constants.IS_USER_APP && (
              <Switch>
                {UserRoutesArr.map((Routes, index) => (
                  <Route
                    key={index}
                    path={Routes.Path}
                    exact={Routes?.Exact}
                    render={(props) => (
                      <Page title={Routes.Title}>
                        <Routes.Component {...props} />
                      </Page>
                    )}
                  />
                ))}
                <PrivateRoute path="/" />
              </Switch>
            )}

            {constants.IS_CORPORATE_APP && <CRoutes />}

            {constants.IS_USER_CORPORATE_APP && (
              <Switch>
                {UserRoutesArr.map((Routes, index) => (
                  <Route
                    key={index}
                    path={Routes.Path}
                    exact={Routes?.Exact}
                    render={(props) => (
                      <Page title={Routes.Title}>
                        <Routes.Component {...props} />
                      </Page>
                    )}
                  />
                ))}
                <Route path="/clogin" component={CSignIn} />
                <Route path="/csignin" component={CSignIn} />
                <Route path="/csignup" exact component={CSignUp} />
                <PrivateRoute path="/" />
              </Switch>
            )}
          </Switch>
        </Router>
      </>
    );
  }
}
export default Home;
