import React from "react";
import { withRouter } from "react-router";
import SidebarData from "./SidebarData";

import "./TNavbar.css";
import Navbar from "./Navbar";

const SNavbar = () => {

  return (
    <Navbar
      sidebarData={SidebarData}

    />
  );
};

export default withRouter(SNavbar);