import dashBoard from "../../../Assets/corporateAdmin/dashboard.svg";
import subscription from "../../../Assets/corporateAdmin/subscription.svg";
import employees from "../../../Assets/corporateAdmin/employees.svg";
import companyDetails from "../../../Assets/corporateAdmin/companyDetails.svg";
import admin from "../../../Assets/corporateAdmin/admin.svg";

import constant from "../../../Constant";

const CORPORATE_APP_PREFIX_URL = constant.IS_CORPORATE_APP ? "" : "/corporate";

export const CSidebarData = [
  {
    title: "Dashboard",
    path: `${CORPORATE_APP_PREFIX_URL}/dashboard`,
    imgName: dashBoard,
    cName: "nav-text",
  },
  // {
  //   title: "Company Schedule",
  //   path: `${CORPORATE_APP_PREFIX_URL}/schedule`,
  //   imgName: schedule,
  //   cName: "nav-text",
  // },
  {
    title: "Subscription",
    path: `${CORPORATE_APP_PREFIX_URL}/subscription`,
    imgName: subscription,
    cName: "nav-text",
  },
  {
    title: "Members",
    path: `${CORPORATE_APP_PREFIX_URL}/members`,
    imgName: employees,
    cName: "nav-text",
  },
  {
    title: "Company Details",
    path: `${CORPORATE_APP_PREFIX_URL}/company-details`,
    imgName: companyDetails,
    cName: "nav-text",
  },
  {
    title: "Admin Settings",
    path: `${CORPORATE_APP_PREFIX_URL}/admin-settings`,
    imgName: admin,
    cName: "nav-text",
  },
];
