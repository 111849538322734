import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import TNavbar from "../../components/commons/Dashboard/TNavbar";
import Schedule from "./Schedule/Schedule";
import Session from "./Session/Session";
import JoinSession from "../users/JoinSession/join-session-component";
import FeedbackByTeacherAfterJoinSession from "./Feedback/FeedbackByTeacherAfterJoinSession";
import NotFound from "../../components/NotFound";
import Page from "../Page";

const TeacherRoutes = [
  {
    Title: "Schedule",
    Exact: true,
    Path: "/teacher/schedule",
    Component: Schedule,
  },
  {
    Title: "Sessions",
    Exact: true,
    Path: "/teacher/sessions",
    Component: Session,
  },
  {
    Title: "Schedule Detail",
    Exact: true,
    Path: "/teacher/schedule/:sessionType/:sessionId",
    Component: Schedule,
  },
  {
    Title: "Session Detail",
    Exact: true,
    Path: "/teacher/sessions/:sessionType/:sessionId",
    Component: Session,
  },
  {
    Title: "Feedback",
    Exact: true,
    Path: "/teacher/schedule/:sessionType/:sessionId/meeting/feedback",
    Component: FeedbackByTeacherAfterJoinSession,
  },
  {
    Title: "Meeting",
    Exact: true,
    Path: "/teacher/schedule/:sessionType/:sessionId/meeting",
    Component: JoinSession,
  },
  {
    Title: "Page Not Found",
    Component: NotFound,
  },
];

const Teachers = (props) => {
  return (
    <>
      {!props.location?.state?.zoomMtgNumber &&
        !props.location?.pathname.includes("meeting/feedback") && <TNavbar />}
      <Switch>
        {TeacherRoutes.map((Routes, index) => {
          if (
            Routes?.Path ===
              "/teacher/schedule/:sessionType/:sessionId/meeting" &&
            !props.location.state
          ) {
            return (
              <Redirect
                to={{
                  pathname: props.location.pathname.replace("/meeting", ""),
                  state: { from: props.location },
                }}
              />
            );
          }
          return (
            <Route
              key={index}
              path={Routes.Path}
              exact={Routes?.Exact}
              render={(props) => (
                <Page title={Routes.Title}>
                  <Routes.Component {...props} />
                </Page>
              )}
            />
          );
        })}
      </Switch>
    </>
  );
};

export default Teachers;
