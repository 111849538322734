import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import ProgressCircularBar from "../../../components/commons/ProgressCircularBar";
import extremeHappy from "../../../Assets/extremeHappy.svg";
import happy from "../../../Assets/happy.svg";
import neutral from "../../../Assets/neutral.svg";
import sad from "../../../Assets/sad.svg";
import extremeSad from "../../../Assets/extremeSad.svg";
import "./feedback.styles.scss";
import constant from "../../../Constant";
import { TEACHERS_FEEDBACK_BY_STUDENT } from "../../../utility/graphQl/mutation";
import { useMutation } from "@apollo/client";

const EMOJI_ARR = [extremeSad, sad, neutral, happy, extremeHappy];

const FeedbackByStudentBeforeVideo = (props) => {
  const intervalRef = useRef(null);
  const { sessionId, sessionType, moodBefore } = props;

  const [progress, setProgress] = useState(0);
  const [color, setColor] = useState("");
  const [emojis, setEmoji] = useState(EMOJI_ARR);
  const [isNext, setisNext] = useState(false);

  const [teacherPreFeedbackByStudent] = useMutation(
    TEACHERS_FEEDBACK_BY_STUDENT
  );

  useEffect(() => {
    const progressValue = 50;
    if (progress < 100) {
      setProgress(progressValue);
    }
    setColor("#5582A7");
  }, [isNext, progress]);

  const handleisNext = useCallback(
    (moodId) => {
      localStorage.setItem("userMood", moodId);
      props.handleVideoCall(moodId);
    },
    [props]
  );

  const handleEmojiClick = useCallback(
    (item, emojiId) => {
      const _arr = isNext ? EMOJI_ARR : [item];
      const _progress = isNext ? 50 : 100;
      setEmoji(_arr);
      setisNext(!isNext);
      setProgress(_progress);
      teacherPreFeedbackByStudent({
        variables: {
          input: {
            sessionType: sessionType, // "GROUP" //"PRIVATE"
            sessionID: sessionId,
            moodBefore: moodBefore ? moodBefore : emojiId + 1,
            platform: constant.APP_PLATFORM,
          },
        },
      });

      if (isNext) {
        clearInterval(intervalRef?.current);
      } else {
        intervalRef.current = setInterval(() => {
          handleisNext(emojiId);
          localStorage.setItem("isBeforeSessionFeedbackSubmitted", true);
        }, 3000);
      }
    },
    [
      isNext,
      handleisNext,
      sessionType,
      sessionId,
      moodBefore,
      teacherPreFeedbackByStudent,
    ]
  );

  React.useEffect(() => {
    return () => clearInterval(intervalRef?.current);
  }, []);

  return (
    <div className="FeedbackByStudentAfterVideo-container">
      <div className="FeedbackByStudentAfterVideo-message-window">
        <div className="FeedbackByStudentAfterVideo-progressor">
          <ProgressCircularBar
            progress={progress}
            size={90}
            strokeWidth={5}
            circleOneStroke="#F3F3F2"
            circleTwoStroke={color}
            progressStatus={true}
          />
          <p>Entering the session...</p>
        </div>
        <div className="FeedbackByStudentAfterVideo-chat">
          <hr />
          <div className="d-flex align-items-end message-box">
            <div className="FeedbackByStudentAvatar" />
            <div className="FeedbackByStudentAfterVideo-teacher-message">
              <p>
                Hi {localStorage.getItem("userfirstName")}, you are about to
                start your session. First, close your eyes and take a deep
                breath. Listen to your body.
                <strong className="d-block"> How are you feeling?</strong>
              </p>
            </div>
          </div>
          <div className="FeedbackByStudentAfterVideo-student-emoji">
            {emojis &&
              emojis.map((item, index) => {
                return (
                  <div
                    className="moods-emoji"
                    key={index}
                    onClick={() => handleEmojiClick(item, index)}
                  >
                    <img src={item} alt="" />
                  </div>
                );
              })}
          </div>

          {isNext && (
            <div className="d-flex align-items-end message-box">
              <div className="FeedbackByStudentAvatar" />
              <div className="FeedbackByStudentAfterVideo-teacher-message">
                <p>
                  I hope the session brings happiness to your day. Enjoy it!
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(FeedbackByStudentBeforeVideo);
