import React from "react";
import crossBtn from "../../Assets/cross-btn.svg";
import "./LogoutDialog.css";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const LogoutDialog = (props) => {
  return (
    <Modal isOpen={true} style={{ width: "auto", maxWidth: "400px" }}>
      <img
        className="logout-dialog-container-form-crossBtn"
        src={crossBtn}
        alt=""
        onClick={() => {
          props.closeLogoutDialog(false);
        }}
      />
      <ModalBody className="logout-dialog-container-form">
        <h1>Are sure you want to log out?</h1>
      </ModalBody>
      <ModalFooter>
        <Button
          className="dialog-yes-btn"
          color="primary"
          onClick={() => {
            props.closeAferYes(false);
          }}
        >
          Yes
        </Button>{" "}
        <Button
          className="dialog-no-btn"
          color="secondary"
          onClick={() => {
            props.closeLogoutDialog(false);
          }}
        >
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LogoutDialog;
