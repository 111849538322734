import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { Line, /* Doughnut, HorizontalBar, */ Chart } from "react-chartjs-2";
import { Row, Col } from "reactstrap";

import Card from "../../components/commons/Card";
import functions from "../../functions";
import { DASHBOARD_DOUGHNUT_BAR_GRAPH } from "./GraphQl";
//import Scrollbar from "../../components/commons/Scrollbar";

import "./corporate.css";
import "./Dashboard.css";

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    //var chart = this.chart;
    var { chart } = this;
    var { width, height, ctx } = chart.chart;

    let fontSize = Chart.helpers.getValueOrDefault(1.3, 1);
    ctx.fontSize = fontSize + "em";
    ctx.color = "#16294C";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.fontWeight = 300;

    var text = `${chart.config.data.text}\n ${functions.employeeToEmployees(
        chart.config.data.text
      )}`,
      lines = text.split("\n"),
      lineheight = 20,
      textX = Math.round((width - ctx.measureText(text).width) / 2 - 50),
      textY = height / 2 - 10;
    if (window.innerWidth < 991) {
      textX = textX + 120;
    }
    for (var i = 0; i < lines.length; i++) {
      if (i === 0) {
        ctx.fontWeight = "bold";
      } else {
        ctx.fontWeight = 300;
      }
      ctx.fillText(lines[i], textX, textY + i * lineheight);
    }
  },
});

const Dashboard = () => {
  const [lineChart, setLineChart] = useState({
    labels: [],
    data: [],
    dates: [],
    engagedEmployeeCount: 0,
    totalSessionAttendeeCount: 0,
  });

  const [/* barChart,  */ setBarChart] = useState({
    labels: [],
    data: [],
    services: [],
    backgroundColor: [],
    borderColor: [],
  });

  const [/* doughnutChart,  */ setDoughnutChart] = useState({
    labels: [],
    data: [],
    backgroundColor: [],
    hoverBackgroundColor: [],
    totalEmployees: 0,
  });

  const [loadDoughnutBarGraphData] = useLazyQuery(
    DASHBOARD_DOUGHNUT_BAR_GRAPH,
    {
      fetchPolicy: "no-cache",
      onCompleted(responce) {
        setLineChart(responce?.corporateDashboard?.viewYourEngagement);
        setDoughnutChart(responce?.corporateDashboard?.employeesWellbeingPlans);
        let data = responce?.corporateDashboard?.sessionPerMonth.data;
        let labels = responce?.corporateDashboard?.sessionPerMonth.labels;
        let newLabels = [];
        labels.forEach((element, index) => {
          newLabels.push(element + "                " + data[index]);
        });
        let barChartData = responce?.corporateDashboard?.sessionPerMonth;
        barChartData["labels"] = newLabels;
        setBarChart(barChartData);
      },
      onError(error) {
        console.log(
          `Error while fectching the doughnut & bar graph data ${error}`
        );
      },
    }
  );

  const onChangeBasis = (value) => {
    loadDoughnutBarGraphData({
      variables: {
        basis: value,
      },
    });
  };

  useEffect(() => {
    loadDoughnutBarGraphData({
      variables: {
        basis: "Monthly",
      },
    });
  }, [loadDoughnutBarGraphData]);

  return (
    <div className="corporate-container">
      <div className="title">Learn from your data</div>
      <Row>
        <ViewYourEngagement
          lineChart={lineChart}
          onChangeBasis={onChangeBasis}
        />
      </Row>
      {/* <Row>
        <EmployeeWellbeingPlans doughnutChart={doughnutChart} />
        <SessionsPerMonth barChart={barChart} />
      </Row> */}
    </div>
  );
};

const ViewYourEngagement = ({ onChangeBasis, lineChart }) => (
  <Col className="mb-4">
    <Card>
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="f20 font-sofia-medium color-fiord mb-3">
          View Your Engagement
        </div>
        <div className="monthly-yearly-box position-relative mb-3">
          <select onChange={(e) => onChangeBasis(e.target.value)}>
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
          </select>
        </div>
      </div>
      <div className="employee-status d-flex flex-wrap">
        <div className="active-employee mr-3  mb-3">
          <div className="d-flex align-items-start">
            <div className="circle-dot mr-2 bg-wedgewood"></div>
            <p className="mb-0">
              <span className="font-sofia-medium color-fiord f16 d-block mb-2">
                {lineChart.totalSessionAttendeeCount}
                &nbsp;
                {`Active ${functions.employeeToEmployees(
                  lineChart.totalSessionAttendeeCount
                )}`}
              </span>
              <span className="font-poppins color-bunting f14 d-block">
                Members registered on the platform
              </span>
            </p>
          </div>
        </div>
        <div className="engaged-employee  mb-3">
          <div className="d-flex align-items-start">
            <div className="circle-dot mr-2 bg-burnt"></div>
            <p className="mb-0">
              <span className="font-sofia-medium color-fiord f16 d-block mb-2">
                {lineChart.engagedEmployeeCount}
                &nbsp;
                {`Engaged ${functions.employeeToEmployees(
                  lineChart.engagedEmployeeCount
                )}`}
              </span>
              <span className="font-poppins color-bunting f14 d-block">
                Members engaged in at least one session
              </span>
            </p>
          </div>
        </div>
      </div>
      <div style={{ padding: "0.5rem 0 0 1rem" }} className="line-graph-block">
        <Line
          data={{
            labels: lineChart.labels,
            datasets: lineChart.data,
          }}
          options={{
            responsive: true,
            elements: {
              fill: false,
              point: {
                radius: 5,
                hoverRadius: 6,
                hoverBorderWidth: 6,
                borderWidth: 2,
                borderColor: "#fff",
                backgroundColor: "#fff",
              },
            },
            legend: {
              display: false,
              labels: {
                fontColor: "#16294C",
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontWeight: 600,
                    fontColor: "#16294C",
                    fontFamily: "Sofia Pro",
                    fontSize: 14,
                  },
                  gridLines: {
                    color: "transparent",
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontWeight: 500,
                    fontColor: "#16294C",
                    fontFamily: "Sofia Pro",
                    fontSize: 14,
                    fontStyle: "normal",
                    stepSize: 1,
                  },
                  gridLines: {
                    color: "#F3F6FF",
                  },
                },
              ],
            },

            tooltips: {
              // Disable the on-canvas tooltip
              enabled: false,

              custom: function (tooltipModel) {
                // Tooltip Element
                console.log(lineChart);
                var tooltipEl = document.getElementById("chartjs-tooltip");

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip";
                  tooltipEl.innerHTML = "<div></div>";
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove("above", "below", "no-transform");
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add("no-transform");
                }

                // Set Text
                if (tooltipModel.body) {
                  var titleLines = tooltipModel.title || [];
                  var bodyLines = tooltipModel.dataPoints;

                  var innerHtml = '<p class="mb-0 f10" >';

                  titleLines.forEach(function (title, i) {
                    var colors = tooltipModel.labelColors[i];
                    innerHtml += `<b style="font-size:0.75rem;color:${
                      colors.borderColor
                    }">&#8226;</b> ${
                      lineChart.dates[tooltipModel.dataPoints[0].index]
                    }`;
                  });
                  innerHtml += "</p>";

                  bodyLines.forEach(function (body, i) {
                    var colors = tooltipModel.labelColors[i];
                    innerHtml +=
                      '<p class="mb-0 pl-2 " style="font-weight:600; color:' +
                      colors.borderColor +
                      '">' +
                      body.value +
                      " " +
                      functions.employeeToEmployees(body.value) +
                      "</p>";
                  });

                  var tableRoot = tooltipEl.querySelector("div");
                  tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = "absolute";
                tooltipEl.style.backgroundColor = "#FFFFFF";
                tooltipEl.style.borderColor = "#F3F3F2";
                tooltipEl.style.borderWidth = 1;
                tooltipEl.style.borderStyle = "solid";
                tooltipEl.style.borderRaduis = 4;
                tooltipEl.style.left =
                  position.left +
                  window.pageXOffset +
                  tooltipModel.caretX -
                  70 +
                  "px";
                tooltipEl.style.top =
                  position.top +
                  window.pageYOffset +
                  tooltipModel.caretY +
                  8 +
                  "px";
                tooltipEl.style.fontFamily = "Poppins";
                tooltipEl.style.fontWeight = 500;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = "0.25rem";
                tooltipEl.style.pointerEvents = "none";
              },
            },
          }}
          height={100}
        />
      </div>
    </Card>
  </Col>
);

/* const EmployeeWellbeingPlans = ({ doughnutChart }) => (
  <Col sm="12" xl="6" className="mb-4">
    <Card>
      <div className="f20 font-sofia-medium color-fiord mb-3">
        Employee Wellbeing Plans
      </div>
      <Doughnut
        data={{
          labels: doughnutChart.labels,
          datasets: [
            {
              data: doughnutChart.data,
              backgroundColor: doughnutChart.backgroundColor,
              hoverBackgroundColor: doughnutChart.hoverBackgroundColor,
            },
          ],
          text: doughnutChart.totalEmployees,
        }}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          showLines: false,
          title: {
            position: "left",
            display: true,
            fontFamily: "Poppins",
            fontSize: 14,
            fontColor: "#16294C",
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
          legend: {
            position: "right",
            align: "center",
            labels: {
              fontSize: 14,
              padding: 14,
              fontFamily: "poppins",
              fontColor: "#435473",
              boxWidth: 15,
            },
            maxHeight: 15,
            display: window.innerWidth < 991 ? false : true,
            fullWidth: false,
            fullHeight: true,
            borderRadius: "50%",
          },
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: function (tooltipModel) {
              // Tooltip Element
              console.log(doughnutChart);
              var tooltipEl = document.getElementById("chartjs-tooltip-2");

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip-2";
                tooltipEl.classList.add("left");
                tooltipEl.innerHTML = "<div></div>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              // Set Text
              if (tooltipModel.body) {
                var innerHtml = '<p class="mb-0 f10" >';
                const data = doughnutChart;
                const index = tooltipModel.dataPoints[0].index;
                var color = data.backgroundColor[index];
                let title = " " + data.labels[index];
                let empCount = data.data[index];
                // let totalCount = data.data.reduce(function (a, b) {
                //     return a + b;
                // }, 0);
                let totalCount = data.totalEmployees;
                console.log(totalCount);
                let percentage = Math.floor(
                  (empCount / totalCount) * 100 + 0.5
                );
                title = title + " " + percentage + "%";
                innerHtml += `<b style="font-size:0.75rem;color:${color}">&#8226;</b> ${title}`;
                innerHtml += "</p>";

                innerHtml +=
                  '<p class="mb-0 pl-2" style="font-weight:600; text-shadow:1px 1px 0px #aaa; color:' +
                  color +
                  '">' +
                  data.data[index] +
                  " " +
                  functions.employeeToEmployees(data.data[index]) +
                  "</p>";

                var tableRoot = tooltipEl.querySelector("div");
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = "absolute";
              tooltipEl.style.backgroundColor = "#FFFFFF";
              tooltipEl.style.borderColor = "#F3F3F2";
              tooltipEl.style.borderWidth = 1;
              tooltipEl.style.borderRaduis = 4;
              tooltipEl.style.borderStyle = "solid";
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + "px";
              tooltipEl.style.top =
                position.top +
                window.pageYOffset +
                tooltipModel.caretY -
                50 +
                "px";
              tooltipEl.style.fontFamily = "Poppins";
              tooltipEl.style.fontWeight = 500;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding = "0.25rem";
              tooltipEl.style.pointerEvents = "none";
            },
          },
        }}
        height={220}
        width={300}
      />
    </Card>
  </Col>
); */

/* const SessionsPerMonth = ({ barChart }) => (
  <Col xl="6" className="mb-4">
    <Card>
      <div className="f20 font-sofia-medium color-fiord mb-3">
        Sessions per month
      </div>
      <div className="row mb-2">
        <div className="col-5 col-xl-4">
          <span className="font-sofia-medium f14 color-nevada">
            Session Type
          </span>
        </div>
        <div className="col-6">
          <span className="font-sofia-medium f14 color-nevada">
            #Of Sessions
          </span>
        </div>
      </div>
      <Scrollbar classes="horizontal-bar">
        <HorizontalBar
          data={{
            labels: barChart.labels,
            datasets: [
              {
                data: barChart.data,
                fill: true,
                backgroundColor: barChart.backgroundColor,
                borderColor: barChart.borderColor,
                minBarLength: 2,
                categoryPercentage: 1.0,
                barPercentage: 0.5,
                barThickness: 10,
                borderDashOffset: 5,
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            legend: {
              align: "center",
              display: false,
            },
            tooltips: {
              enabled: false,
            },
            layout: {
              padding: {
                left: 10,
                right: 0,
                top: 0,
                bottom: 0,
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false,
                    beginAtZero: false,
                    min: 0.5,
                  },
                  gridLines: false,
                  stacked: true,
                },
              ],
              yAxes: [
                {
                  ticks: {
                    textAlign: "left",
                    beginAtZero: true,
                    display: true,
                    fontSize: 14,
                    fontFamily: "poppins",
                    labelOffset: 2,
                    padding: 10,
                    fontColor: "#16294C",
                  },
                  gridLines: false,
                  stacked: true,
                },
              ],
            },
          }}
          width={350}
          height={250}
        />
      </Scrollbar>

      <div className="d-flex align-items-center justify-content-center  flex-wrap">
        {barChart.services.map((item, index) => (
          <div key={index} className="mr-2 d-flex align-items-center mt-3">
            <div
              className="circle-dot mr-1"
              style={{ backgroundColor: item.barChartColor }}
            ></div>
            <div className="font-poppins f14 color-fiord">{item.name}</div>
          </div>
        ))}
      </div>
    </Card>
  </Col>
); */

export default Dashboard;
