import React, { useState, createContext, useContext, useEffect } from "react";
import {
  SIGN_IN,
  SELF_SERVE_CORPORATE_SIGN_IN,
} from "../utility/graphQl/mutation";
import { QUERY_USER } from "../utility/graphQl/query";
import { useMutation, useLazyQuery } from "@apollo/client";
import { setGlobalInfoCookie, clearStorage } from "../utility/Function";
import {
  logAnalyticsEvent,
  setAnalyticsUserId,
} from "../utility/FirebaseAnalytics";

import constants from "../Constant";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

const AuthContext = createContext(null);

export const COMPANY_ADMIN_ROLE = "COMPANY-ADMIN";
export const STUDENT_ROLE = "STUDENT";
export const TEACHER_ROLE = "TEACHER";

const _setCommonLocalStorage = (user) => {
  const { firstName, lastName, id, email, userdetail } = user;

  const companyName = userdetail?.studentdetail?.company?.name || "NA";

  // Remove student specific items
  localStorage.removeItem("studentID");
  localStorage.removeItem("studentPhoto");
  localStorage.removeItem("WOEstudentUserId");
  localStorage.removeItem("shouldOnboard");

  // Remove teacher specific items
  localStorage.removeItem("teacherId");
  localStorage.removeItem("teacherPhoto");

  // Remove company admin specific items
  localStorage.removeItem("paymentLink");
  localStorage.removeItem("companyID");
  localStorage.removeItem("roleID");
  localStorage.removeItem("companyLocation");

  // Set common items
  localStorage.setItem("companyName", companyName);
  localStorage.setItem("userfirstName", firstName);
  localStorage.setItem("userlastName", lastName);
  localStorage.setItem("userID", id);
  localStorage.setItem("userEmailId", email);
};

const setStudentLocalStorage = (user) => {
  const {
    id,
    userdetail: { studentdetail, photo },
  } = user;

  _setCommonLocalStorage(user);

  localStorage.setItem("studentID", studentdetail.id);
  localStorage.setItem("studentPhoto", photo);
  localStorage.setItem("WOEstudentUserId", id); // TODO: remove from codebase and refactor all dependants

  if (!studentdetail?.onboarding) {
    localStorage.setItem("shouldOnboard", "true");
  } else {
    localStorage.removeItem("shouldOnboard");
  }
};

const setTeacherLocalStorage = (user) => {
  const {
    userdetail: { teacherdetail, photo },
  } = user;

  _setCommonLocalStorage(user);

  localStorage.setItem("teacherID", teacherdetail.id);
  localStorage.setItem("teacherPhoto", photo);
};

const setCompanyAdminLocalStorage = (user) => {
  const {
    userdetail: { studentdetail, photo, role },
  } = user;
  const paymentLink = studentdetail.company.paymentLink;

  _setCommonLocalStorage(user);

  if (paymentLink) {
    localStorage.setItem("paymentLink", paymentLink);
  }
  localStorage.setItem("companyID", studentdetail.company.id);
  localStorage.setItem("teacherPhoto", photo);
  localStorage.setItem("studentID", studentdetail.id);
  localStorage.setItem("roleID", role[0].id);

  studentdetail &&
    studentdetail.corporatecompanyadminSet?.map((res) =>
      localStorage.setItem("companyLocation", res?.location?.location)
    );
};

const reportStudentLogin = (user) => {
  const {
    id,
    userdetail: { studentdetail },
  } = user;

  const companyName = studentdetail?.company?.name || "NA";

  logAnalyticsEvent("login", {
    user_id: id,
    user_id_wone: id,
    company_name: companyName,
  });
};

const reportTeacherLogin = (user) => {
  const { id, firstName, lastName } = user;

  logAnalyticsEvent("practitioner_login", {
    user_id: id,
    user_id_wone: id,
    first_name: firstName,
    last_name: lastName,
  });
};

const reportCompanyAdminLogin = (user) => {
  const {
    id,
    userdetail: { studentdetail },
    firstName,
    lastName,
  } = user;

  const companyName = studentdetail.company.name || "NA";

  logAnalyticsEvent("company_admin_login", {
    user_id: id,
    user_id_wone: id,
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
  });
};

export const getUserRoleType = (user) => {
  if (
    user &&
    constants.IS_CORPORATE_APP &&
    user.userdetail.role.find((item) => item.name === COMPANY_ADMIN_ROLE)
  ) {
    return COMPANY_ADMIN_ROLE;
  } else if (
    user &&
    user.userdetail.role.find((item) => item.name === STUDENT_ROLE)
  ) {
    return STUDENT_ROLE;
  } else if (
    user &&
    user.userdetail.role.find((item) => item.name === TEACHER_ROLE)
  ) {
    return TEACHER_ROLE;
  } else {
    return null;
  }
};

export const checkUserHasRole = (user, role) => {
  if (user?.userdetail?.role) {
    return user.userdetail.role.some((item) => item.name === role);
  } else {
    return undefined;
  }
};

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [authToken, setAuthToken] = useState(false);
  const [user, setUser] = useState(null);
  const [signIn, { loading, error }] = useMutation(
    constants.IS_CORPORATE_APP ? SELF_SERVE_CORPORATE_SIGN_IN : SIGN_IN
  );

  const handleUser = (user) => {
    const roleType = getUserRoleType(user);

    setUser(user);
    setAnalyticsUserId(user.id);
    setGlobalInfoCookie({ id: user.id });

    if (roleType === COMPANY_ADMIN_ROLE) {
      setCompanyAdminLocalStorage(user);
    } else if (roleType === STUDENT_ROLE) {
      setStudentLocalStorage(user);
    } else if (roleType === TEACHER_ROLE) {
      setTeacherLocalStorage(user);
    } else {
      throw new Error(
        `Unexpected role type ${roleType} for user with id ${user.id}`
      );
    }
  };

  const [getUser] = useLazyQuery(QUERY_USER, {
    onCompleted(result) {
      handleUser(result.user);
    },
    onError() {
      logout();
      history.go(0);
    },
  });

  const login = (variables) =>
    signIn({
      variables,
    }).then(
      (success) => {
        const { authToken, user, paymentLink } = success.data.signIn;
        const roleType = getUserRoleType(user);

        localStorage.setItem("Authtoken", authToken);

        if (paymentLink) {
          localStorage.setItem("paymentLink", paymentLink);
        }

        if (roleType === COMPANY_ADMIN_ROLE) {
          reportCompanyAdminLogin(user);
        } else if (roleType === STUDENT_ROLE) {
          reportStudentLogin(user);
        } else if (roleType === TEACHER_ROLE) {
          reportTeacherLogin(user);
        } else {
          throw new Error(
            `Unexpected role type ${roleType} for user with id ${user.id}`
          );
        }

        setAuthToken(authToken);
        handleUser(user);
        getUser({
          variables: {
            email: user.email,
          },
        });
        return {
          ...success.data.signIn,
          roleType,
        };
      },
      (error) => {
        logout();

        return error;
      }
    );

  const logout = () => {
    clearStorage();
    setAuthToken(null);
    setUser(null);
    setAnalyticsUserId(null);
  };

  useEffect(() => {
    const token = localStorage.getItem("Authtoken");
    const userId = localStorage.getItem("userID");

    if (token) {
      const decoded = jwt_decode(token);
      getUser({
        variables: {
          email: decoded.username,
        },
      });
    }

    setAuthToken(token);
    setAnalyticsUserId(userId);
  }, [getUser]);

  return (
    // Using the provider so that ANY component in our application can
    // use the values that we are sending.
    <AuthContext.Provider
      value={{
        authToken,
        login,
        logout,
        loading,
        error,
        user,
        handleUser,
        setAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom Auth hook
export const useAuth = () => useContext(AuthContext);
