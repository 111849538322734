import React from "react";
import FormEditFeedback from "../Feedback/FormEditFeedback";
import SessionContainer from "../../../components/commons/SessionContainer";
import SessionDetail from "../../../components/commons/SessionDetail/session-detail.component";
import { useSession } from "./useSession";

const SESSIONS_HEADING = "Upcoming live sessions";

const Sessions = (props) => {
  const {
    loading,
    openAddEditPopup,
    handleSessionCardClick,
    modelStatus,
    upcomingSession,
    sessionDetailData,
    closeDetailsDialog,
    handleRegisterPress,
    closeFeedbackForm,
    handleAddEditFeedback,
    handleunRegisterPress,
    handleClickToJoin,
    listName,
    listPosition,
  } = useSession();

  return (
    <div className="corporate-container">
      <div className="tsession-calendar">
        {!!upcomingSession.length && (
          <SessionContainer
            userType="teacher"
            sessionData={upcomingSession}
            heading={SESSIONS_HEADING}
            history={props.history}
            loading={loading}
            handleRegisterPress={handleRegisterPress}
            handleSessionCardClick={handleSessionCardClick}
            handleAddEditFeedback={handleAddEditFeedback}
            handleClickToJoin={handleClickToJoin}
            listName={"PractitionerSessionsUpcomingGroupSessions"}
          />
        )}
      </div>

      {modelStatus && (
        <SessionDetail
          userType="teacher"
          session={sessionDetailData}
          handleRegisterPress={handleRegisterPress}
          handleunRegisterPress={handleunRegisterPress}
          closeDetailsDialog={closeDetailsDialog}
          history={props.history}
          loading={loading}
          handleAddEditFeedback={handleAddEditFeedback}
          handleClickToJoin={handleClickToJoin}
          listName={listName}
          listPosition={listPosition}
        />
      )}
      {openAddEditPopup && (
        <FormEditFeedback
          sessionDetailData={sessionDetailData}
          closeForm={closeFeedbackForm}
        />
      )}
    </div>
  );
};

export default Sessions;
